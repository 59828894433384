import React from "react";

function LoginInput({ type, name, title, onChange, placeholder,value ,error,pl=0,pr=0 ,isDisabled=false}) {
  return (
    <div>
      <div className="text-sm font-bold text-gray-700 tracking-wide">
        {title}
      </div>
      <input
        disabled={isDisabled}
        name={name}
        onChange={onChange}
        className={`w-full pl-${pl} pr-${pr} text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-green-500`}
        type={type}
        placeholder={placeholder}
        value={value}
      />

      {error && error[name] ? (
        <p className="text-red-500">{error[name]}</p>
      ) : null}
    </div>
  );
}

export default LoginInput;
