import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage ,isSupported} from 'firebase/messaging';
import { getToken as getAuthToken } from "./Authentication/Auth";
const firebaseConfig = {
    apiKey: "AIzaSyCWzBex8UUlVONZeIVQ3bvkaGQy5S-QQiY",
    authDomain: "vehicle-request-6f082.firebaseapp.com",
    projectId: "vehicle-request-6f082",
    storageBucket: "vehicle-request-6f082.appspot.com",
    messagingSenderId: "1059525933538",
    appId: "1:1059525933538:web:efbc55138aa53ef09f61bb",
    measurementId: "G-FGH2FDVCSM"
  };
const app = initializeApp(firebaseConfig);

const messaging = async () => {
  const supportResolver = await isSupported();
  if (supportResolver) {
    return getMessaging(app);
  } else {
    return {};
  }
};

const storeFcmToken = async(token) => {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/notification/storeFcm`, {
    method: "POST",
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    }),
    body: JSON.stringify({
      token: token,
    }),
  })
    .then((res) => res.json())
    .then((response) => response)
    .catch((error) => console.log(error));
};


export const requestPermission = () => {
  console.log("Requesting User Permission......");
  if ("Notification" in window) {
    Notification.requestPermission().then(async(permission) => {
      if (permission === "granted") {
        console.log("Notification User Permission Granted.");
        return getToken(await messaging(), {
          vapidKey: `BBVrk1icNihP5Hf8ZWMxjtLdi3LLJ6Dz58tNEDDt3QsGpOuoRa4dPeg8XiaYibzIhHuU9aKvwTbxSNRZKUV6cxQ`,
        })
          .then(async (currentToken) => {
            if (currentToken) {
              await storeFcmToken(currentToken);
              console.log("Client Token: ", currentToken);
            } else {
              console.log("Failed to generate the app registration token.");
            }
          })
          .catch((err) => {
            console.log(
              "An error occurred when requesting to receive the token.",
              err
            );
          });
      } else {
        console.log("User Permission Denied.");
      }
    });
  }
};

export const onMessageListener = () =>
  new Promise(async (resolve) => {
    onMessage(await messaging(), (payload) => {
      resolve(payload);
    });
  });