import React from "react";
import { REQUEST_STATUSES, USER_TYPES } from "../../config/constants/general";

export default function ActionButtons({ status, type, onApprove, onDiscard ,onDecline, cancelStatus}) {
  return (
    <>
      {type === USER_TYPES.DIRECTORS && status === REQUEST_STATUSES.PENDING ? (
        <>
          <div className="mt-4  mb-0">
            <button
              className="bg-green-500 p-2 text-white rounded-xl"
              onClick={onApprove}
            >
              {type === USER_TYPES.DIRECTORS ? "Approve" : "Provide"}
            </button>
            <button
              className="bg-red-500 p-2 text-white rounded-xl ml-4"
              onClick={onDiscard}
            >
              Discard
            </button>
          </div>
        </>
      ) : type === USER_TYPES.DIRECTORS &&
        status === REQUEST_STATUSES.DECLINE ? (
        <>
          <div className="mt-4  mb-0">
            <button
              className="bg-green-500 p-2 text-white rounded-xl"
              onClick={onApprove}
            >
              {type === USER_TYPES.DIRECTORS ? "Approve" : "Provide"}
            </button>
          </div>
        </>
      ) : null}
      {type === USER_TYPES.ADMIN && status === REQUEST_STATUSES.APPROVED ? (
        <>
          <div className="mt-4  mb-0">
            <button
              className="bg-green-500 p-2 text-white rounded-xl"
              onClick={onApprove}
            >
              {type === USER_TYPES.DIRECTORS ? "Approve" : "Provide"}
            </button>
            <button
              className="bg-red-500 p-2 text-white rounded-xl ml-4"
              onClick={onDiscard}
            >
              Discard
            </button>
          </div>
        </>
      ) : type === USER_TYPES.ADMIN && status === REQUEST_STATUSES.DECLINE ? (
        <>
          <div className="mt-4  mb-0">
            <button
              className="bg-green-500 p-2 text-white rounded-xl"
              onClick={onApprove}
            >
              {type === USER_TYPES.DIRECTORS ? "Approve" : "Provide"}
            </button>
          </div>
        </>
      ) : null}

      {cancelStatus ? (
        <>
          <div className="mt-4  mb-0">
            <button
              className="bg-red-500 p-2 text-white rounded-xl"
              onClick={onDecline}
            >
              Cancel
            </button>
          </div>
        </>
      ) : null}
    </>
  );
}
