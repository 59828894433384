import React from "react";

function LoginSelect({ type, name, title, onChange, children,value ,error,px='px-4',isDisabled}) {
  return (
    <div>
      <div className="text-sm font-bold text-gray-700 tracking-wide">
        {title}
      </div>
      <select
        disabled={isDisabled}
        class={`block appearance-none w-full bg-gray-200 border  text-gray-700 py-2 mt-3 ${px} rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
        name={name}
        value={value}
        onChange={onChange}
        // onBlur={handleBlur}
      >
        {children}
      </select>
      {error && error[name] ? (
        <p className="text-red-500">{error[name]}</p>
      ) : null}
    </div>
  );
}

export default LoginSelect;
