import React, { useEffect, useState } from "react";
import Logo from "../assets/nbroLogo.jpeg";
import { isAuthenticated } from "../Authentication/Auth";
import {
  ArrowRightIcon,
} from "@heroicons/react/solid";
import { InstallPWA } from "../components";

function Home() {

  return (
    <div className="w-screen h-screen">
      <div className="h-20"></div>
      <div class="relative z-0 h-2/3  self-center object-cover  justify-items-start  rounded-3xl overflow-hidden mx-2 sm:mx-20">
        <div class="absolute inset-0 flex flex-col justify-items-center justify-center z-10 h-auto">
          <div className="w-72 mx-auto">
          <h2 class="text-3xl font-bold text-start tracking-tight text-gray-500 sm:text-4xl">NBRO<br/>Mobility Pal</h2>
          </div>
        

          <img
            className="h-40 my-5 mt-8 w-auto object-cover mx-auto rounded-2xl "
            alt="nbro logo"
            src={Logo}
          ></img>
          <h3 class="text-lg font-medium  text-slate-500 text-center mt-5">
            Online Platform to request and <br/>manage vehicle requests
          </h3>
          {isAuthenticated() ? <div className="flex flex-row mx-auto">
           
              <a
                type="button"
                className="ml-4 mt-5 text-center inline-block px-2 py-3 w-40 bg-red-900 bg-opacity-5 border-2 border-red-900 text-red-900 font-semibold  text-sm  leading-tight uppercase rounded-2xl shadow-md hover:bg-opacity-10 hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                href="/dashboard"
              >
                <span className="flex justify-center align-middle">Enter<ArrowRightIcon className="w-4 ml-2 " /></span>
              </a>
            </div> : ( // </div> //   </a> //     Logout //   > //     className="sm:ml-4 mt-5 text-center inline-block px-2 py-4 w-60 bg-blue-500 text-white font-medium text-base leading-tight uppercase rounded-full shadow-md hover:bg-blue-600 hover:shadow-lg focus:bg-blue-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg transition duration-150 ease-in-out" //     href="/logout" //   <a // <div className="flex flex-col sm:flex-row mx-auto">
            <div className="flex flex-row mx-auto">
              <a
                type="button"
                className=" mt-5 text-center inline-block px-2 py-3 w-40 bg-red-900 bg-opacity-25 border-2 border-transparent text-slate-800 font-semibold text-sm  leading-tight uppercase rounded-2xl  shadow-md hover:bg-opacity-20 hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out"
                href="/register"
              >
                Register Now
              </a>
              <a
                type="button"
                className="ml-4 mt-5 text-center inline-block px-2 py-3 w-40 bg-red-900 bg-opacity-5 border-2 border-red-900 text-red-900 font-semibold  text-sm  leading-tight uppercase rounded-2xl shadow-md hover:bg-opacity-10 hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                href="/login"
              >
                Login
              </a>
            </div>
          )}
        </div>
      </div>
      <InstallPWA/>
      <footer class="fixed bottom-0 z-20 w-full py-4 border-t border-gray-200 shadow-lg md:flex md:items-center md:justify-center md:p-6 bg-slate-200">
        <div class="text-sm text-gray-600 text-center">
          © 2024{" "}
          <a
            href="https://www.nbro.gov.lk/index.php?lang=en"
            class="hover:underline"
          >
            National Building Research Organisation
          </a>
          . All Rights Reserved.
          <p>Designed and Developed by <a href="https://pavanpabs.github.io/" class="hover:underline">PavanS</a></p>
        </div>
      </footer>
    </div>
  );
}

export default Home;
