import React, { useEffect, useState } from "react";
import newFile from "../assets/new-file.svg";
import { getToken,getUserLevel ,getUserId} from "../Authentication/Auth";
import { MODAL_TYPES, REQUEST_STATUSES, USER_TYPES } from "../config/constants/general";
import Modal from "../components/Modal";
import LoginInput from "../components/LoginInput";
import LoginSelect from "../components/LoginSelect";
import { ListItem } from "../components";
import { declineRequest, editRequest } from "./action";

function Dashboard() {
  const userLevel = getUserLevel();
  const userId = getUserId();
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [requestActive, setRequestActive] = useState();
  const [requestHistory, setRequestHistory] = useState();
  const [isModal, setIsModal] = useState(false);
  const [modalType,setModalType] = useState(MODAL_TYPES.NEW_REQUEST);
  const [errors, setErrors] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [values, setValues] = useState({from:'',to:'',distance:0,time:'',date:'',vehicleType:'',description:'',vehicleNo:'',driverName:'',driverTele:'',isDisabled:false,isInformed:false,reason:''});

  useEffect(() => {
    loadRequests();
  }, []);

  const loadRequests = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/request`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (userLevel !== USER_TYPES.USER) {
          const otherReqs = response.filter(
            (item) => item?.requestedBy?._id !== userId && item
          );
          const myReqs = response.filter(
            (item) => item?.requestedBy?._id === userId && item
          );
          setRequestActive(otherReqs);
          setRequestHistory(myReqs);
        } else {
          setRequestActive(response);
        }
      })
      .catch((error) => console.log(error));
  };

  const submitRequest = () => {
    setIsLoading(true)
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/request`,
      {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify({ 
          from: values.from,
          to: values.to,
          distance: values.distance,
          reqDateTimeFrom: values.reqDateTimeFrom,
          reqDateTimeTo: values.reqDateTimeTo,
          vehicleType: values.vehicleType,
          description: values.description}),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        loadRequests();
        setAlert(false);
        setIsModal(false);
        setIsLoading(false)
      })
      .catch((error) => console.log(error));
  };

  const handleEditRequest = async () => {
    const res = await editRequest(values, selectedData?.id);
    if (res) {
      loadRequests();
      setAlert(false);
      setIsModal(false);
    }
  };

  const handleDecline = async () => {
    const res = await declineRequest(values, selectedData?.id);
    if (res) {
      loadRequests();
      setAlert(false);
      setIsModal(false);
    }
  };


  const handleApproval = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/request/approval`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify({
        id: selectedData?.id,
        status: selectedData?.type,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        loadRequests();
        setAlert(false);
      })
      .catch((error) => console.log(error));
  };

  const handleDelegation = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/request/delegation`, {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify({
        id: selectedData?.id,
        status: selectedData?.type,
        vehicleNo: values.vehicleNo,
        driverName: values?.driverName,
        driverPhone: values?.driverTele,
        description: values?.description,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        loadRequests();
        setAlert(false);
        setIsModal(false);
      })
      .catch((error) => console.log(error));
  };

  const validate= async () => {
    const errors = {};
    let phoneNo = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (modalType === MODAL_TYPES.NEW_REQUEST || modalType === MODAL_TYPES.EDIT_REQUEST ) {
      if (!values.from) {
        errors.from = "Starting position of journey is Required*";
      }
      if (!values.to) {
        errors.to = "Ending position of journey is Required*";
      }
      if (!values.vehicleType || values.vehicleType == "Select") {
        errors.vehicleType = "Please Select vehicle type*";
      }
      if (!values.distance || values.distance <= 0) {
        errors.distance = "Invalid distance*";
      }
      if (!values.reqDateTimeFrom) {
        errors.reqDateTimeFrom = "Required date and time from is a required field*";
      } else if (
        new Date(values.reqDateTimeFrom) < new Date()
      ) {
        errors.reqDateTimeFrom = "Date and time should be grater than current date time";
      }

      if (!values.reqDateTimeTo) {
        errors.reqDateTimeTo = "Required date and time to is a required field*";
      } else if (
        new Date(values.reqDateTimeTo) < new Date(values.reqDateTimeFrom) 
      ) {
        errors.reqDateTimeTo = "Date and time should be grater than 'Required date and time from' field";
      }

    } else if (modalType === MODAL_TYPES.PROVIDE_VEHICLE) {
      if (!values.vehicleNo) {
        errors.vehicleNo = "Vehicle Number is required !";
      }

      if (!values.driverName) {
        errors.driverName = "Driver's name is required !";
      }

      if (!values.driverTele) {
        errors.driverTele = "Mobile Number is Required !";
      } else if (!values.driverTele.match(phoneNo)) {
        errors.driverTele = "Mobile Number format is incorrect !";
      }
    }
    setErrors(errors)
    return errors
  }
  
  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.name) {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
  };
  const requestForm = () => {
    if (
      modalType === MODAL_TYPES.NEW_REQUEST ||
      modalType === MODAL_TYPES.EDIT_REQUEST
    ) {
      return (
        <div className="text-sm  w-full col-span-5">
          <div class="mb-6">
            <LoginInput
              isDisabled={values.isDisabled}
              type={"text"}
              name={"from"}
              onChange={handleChange}
              value={values.from}
              placeholder={"Eg - Head Office"}
              error={errors}
              title={"Starting location"}
            />
          </div>
          <div class="mb-6">
            <LoginInput
              isDisabled={values.isDisabled}
              type={"text"}
              name={"to"}
              onChange={handleChange}
              value={values.to}
              placeholder={"Eg - Kandy Office"}
              error={errors}
              title={"Destination"}
            />
          </div>
          <div class="mb-6">
            <LoginInput
              isDisabled={values.isDisabled}
              type={"number"}
              name={"distance"}
              onChange={handleChange}
              value={values.distance}
              placeholder={"Eg - 33"}
              error={errors}
              title={"Distance (km)"}
            />
          </div>
          <div className="text-sm font-bold text-gray-700 tracking-wide">
            {"Planned date and time"}
          </div>
          <div class="flex flex-col xl:flex-row pt-1">
            <div class="z-0 mb-4 lg:mt-0 mt-2 w-full xl:w-1/2 xl:mr-2">
              <LoginInput
                isDisabled={values.isDisabled}
                type={"datetime-local"}
                name={"reqDateTimeFrom"}
                onChange={handleChange}
                value={values.reqDateTimeFrom}
                placeholder={"Eg - 105815"}
                error={errors}
                title={"From"}
              />
            </div>
            <div class="z-0 mb-4 w-full xl:w-1/2 lg:mt-0 mt-2 xl:ml-2">
              <LoginInput
                isDisabled={values.isDisabled}
                type={"datetime-local"}
                name={"reqDateTimeTo"}
                onChange={handleChange}
                value={values.reqDateTimeTo}
                placeholder={"Eg - 105815"}
                error={errors}
                title={"To"}
              />
            </div>
          </div>

          <div class="mb-6">
            <LoginSelect
              isDisabled={values.isDisabled}
              type={"select"}
              name={"vehicleType"}
              onChange={handleChange}
              value={values.vehicleType}
              placeholder={"Eg - 105815"}
              error={errors}
              title={"Vehicle Type"}
            >
              <option>Select</option>
              <option>Van</option>
              <option>Cab</option>
              <option>Car</option>
            </LoginSelect>
          </div>
          <div className="text-sm font-bold text-gray-700 tracking-wide">
            {"Reason / More Info"}
          </div>
          <textarea
            disabled={values.isDisabled}
            name={"description"}
            className="text-base w-full mt-2  border-2 py-3 px-2"
            placeholder=""
            onChange={handleChange}
            // disabled={!ratingEdit}
            value={values.description}
          ></textarea>
        </div>
      );
    } else if (modalType === MODAL_TYPES.DECLINE_REQUEST) {
      return (
        <div className="text-sm  w-full col-span-5">
          <div className="text-sm font-bold text-gray-700 tracking-wide">
            {"Reason"}
          </div>
          <textarea
            disabled={values.isDisabled}
            name={"reason"}
            className="text-base w-full mt-2  border-2 py-3 px-2"
            placeholder=""
            onChange={handleChange}
            // disabled={!ratingEdit}
            value={values.reason}
          ></textarea>
        </div>
      );
    } else {
      return (
        <div className="text-sm  w-full col-span-5">
          <div class="mb-6">
            <LoginInput
              isDisabled={values.isDisabled}
              type={"text"}
              name={"vehicleNo"}
              onChange={handleChange}
              value={values.vehicleNo}
              placeholder={"Eg - CAB 1212"}
              error={errors}
              title={"Vehicle Number *"}
            />
          </div>
          <div class="mb-6">
            <LoginInput
              isDisabled={values.isDisabled}
              type={"text"}
              name={"driverName"}
              onChange={handleChange}
              value={values.driverName}
              placeholder={"Eg - Piyal Perera"}
              error={errors}
              title={"Driver's Name *"}
            />
          </div>

          <div class="mb-6">
            <LoginInput
              isDisabled={values.isDisabled}
              type={"tel"}
              pattern={"[0-9]{10}"}
              name={"driverTele"}
              onChange={handleChange}
              value={values.driverTele}
              placeholder={"Eg - 0711234123"}
              error={errors}
              title={"Driver's Phone number *"}
            />
          </div>

          <div className="text-sm font-bold text-gray-700 tracking-wide">
            {"More Info"}
          </div>
          <textarea
            disabled={values.isDisabled}
            name={"description"}
            className="text-base w-full mt-2  border-2 py-3 px-2"
            placeholder=""
            onChange={handleChange}
            // disabled={!ratingEdit}
            value={values.description}
          ></textarea>
          {!values.isDisabled && (
            <div class="mt-6 flex">
              <input
                type="checkbox"
                className="w-5 stroke-slate-800"
                onChange={() =>
                  setValues({ ...values, isInformed: !values.isInformed })
                }
                name={"isInformed"}
              ></input>{" "}
              <p className="ml-3 text-base text-red-800 font-semibold">
                Informed to the driver
              </p>
            </div>
          )}
        </div>
      );
    }
  };

  const handleOpen = (e) => {
    e.preventDefault()
    setIsModal(true);
    setModalType(MODAL_TYPES.NEW_REQUEST)
    setValues({isInformed:true})
  };

  return (
    <>
      <Modal
        width="w-6/12"
        widthSm="w-10/12"
        buttons={values.isDisabled && "left"}
        isButtonEnabled={!isLoading && values.isInformed}
        open={isModal}
        handleCancel={() => setIsModal(false)}
        handleOk={async () => {
          if (values?.isDisabled) {
            setIsModal(false);
            return;
          }
          const error = await validate();
          if (Object.keys(error).length === 0) {
            setAlert(true);
          }
        }}
        title={modalType}
      >
        <>{requestForm()}</>
      </Modal>
      <Modal
        width="w-4/12"
        widthSm="w-4/12"
        open={alert}
        isButtonEnabled={!isLoading}
        handleCancel={() => setAlert(false)}
        handleOk={() => {
          if (!isModal) {
            handleApproval();
          } else {
            if (modalType === MODAL_TYPES.PROVIDE_VEHICLE) {
              handleDelegation();
            } else if (modalType === MODAL_TYPES.NEW_REQUEST) {
              submitRequest();
            } else if (modalType === MODAL_TYPES.DECLINE_REQUEST) {
              handleDecline();
            } else if (modalType === MODAL_TYPES.EDIT_REQUEST) {
              handleEditRequest();
            }
          }
        }}
        title={"Are you sure?"}
      >
        <>
          <p className="text-center">This step can not be undone!</p>
        </>
      </Modal>
      <div className="h-screen flex">
        <div className="flex-1 flex overflow-hidden pt-10 ">
          <div className="flex-1 overflow-y-scroll">
            <div className="inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden flex justify-center">
                <ul
                  className=" nav nav-tabs nav-justified flex flex-row flex-wrap list-none border-b-0 pl-0  fixed w-4/5 lg:w-2/4 bg-white z-9"
                  id="tabs-tabJustify"
                  role="tablist"
                >
                  <li
                    className="nav-item flex-grow text-center"
                    role="presentation"
                  >
                    <a
                      href="#tabs-homeJustify"
                      className=" nav-link w-full block font-semibold text-base leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent active"
                      id="tabs-home-tabJustify"
                      data-bs-toggle="pill"
                      data-bs-target="#tabs-homeJustify"
                      role="tab"
                      aria-controls="tabs-homeJustify"
                      aria-selected="true"
                    >
                      {userLevel === USER_TYPES.USER
                        ? "My Requests"
                        : userLevel === USER_TYPES.DIRECTORS
                        ? "Pending Approvals"
                        : "To Be Provided"}
                    </a>
                  </li>
                  {userLevel !== USER_TYPES.USER && (
                    <li
                      className="nav-item flex-grow text-center"
                      role="presentation"
                    >
                      <a
                        href="#tabs-profileJustify"
                        className=" nav-link w-full block font-semibold text-base leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent                           "
                        id="tabs-profile-tabJustify"
                        data-bs-toggle="pill"
                        data-bs-target="#tabs-profileJustify"
                        role="tab"
                        aria-controls="tabs-profileJustify"
                        aria-selected="false"
                      >
                        My Requests
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>

            <div className="flex justify-center">
              <div
                className=" w-4/5 lg:w-2/4 tab-content mt-20"
                id="tabs-tabContentJustify"
              >
                <div
                  className="tab-pane fade show active"
                  id="tabs-homeJustify"
                  role="tabpanel"
                  aria-labelledby="tabs-home-tabJustify"
                >
                  <div className="items-start">
                    {requestActive?.map(
                      (item, i) => (
                        <ListItem
                          key={item?.id}
                          name={item?.name}
                          from={item?.from}
                          to={item?.to}
                          distance={item?.distance}
                          item={item}
                          requiredDate={item?.reqDate}
                          mobile={item.requestedBy?.mobile}
                          requiredTime={item?.reqTime}
                          vehicleType={item?.vehicleType}
                          description={item?.description}
                          status={item?.status}
                          lastUpdated={null}
                          createdAt={item?.createdAt}
                          type={userLevel}
                          onViewDetails={(type) => {
                            setValues({
                              vehicleNo: item.providedVehicle?.vehicleNo,
                              driverName: item.providedVehicle?.driverName,
                              driverTele: item.providedVehicle?.driverPhone,
                              description: item?.providedVehicle?.description,
                              isDisabled: true,
                            });
                            setModalType(type);
                            setIsModal(true);
                          }}
                          onApprove={() => {
                            if (userLevel === USER_TYPES.ADMIN) {
                              setSelectedData({
                                id: item?._id,
                                type: REQUEST_STATUSES.PROVIDED,
                              });
                              setModalType(MODAL_TYPES.PROVIDE_VEHICLE);
                              setValues({isInformed:false})
                              setIsModal(true);
                            } else if (userLevel === USER_TYPES.DIRECTORS) {
                              setSelectedData({
                                id: item?._id,
                                type: REQUEST_STATUSES.APPROVED,
                              });
                              setAlert(true);
                            }
                          }}
                          onDiscard={() => {
                            setSelectedData({
                              id: item?._id,
                              type: REQUEST_STATUSES.DECLINE,
                            });
                            setAlert(true);
                          }}
                          onDecline={() => {
                            setSelectedData({
                              id: item?._id,
                              type: REQUEST_STATUSES.DECLINE,
                            });
                            setIsModal(true);
                            setModalType(MODAL_TYPES.DECLINE_REQUEST);
                          }}
                          onEdit={() => {
                            console.log(item);
                            setSelectedData({
                              id: item?._id,
                            });
                            setValues({
                              from: item.from,
                              to: item.to,
                              distance: item.distance,
                              time: item.reqTime,
                              date: item?.reqDate?.split("T")[0],
                              vehicleType: item.vehicleType,
                              description: item?.description,
                              isDisabled: false,
                            });
                            setModalType(MODAL_TYPES.EDIT_REQUEST);
                            setIsModal(true);
                          }}
                        />
                      )
                      // if (i === 0) {
                      //   return <h1 className="text-center mt-28 text-lg font-bold w-5/5" >No Data...</h1>;
                      // }
                    )}
                    {requestActive?.length === 0 && (
                      <h1 className="text-center mt-28 text-lg font-bold w-5/5">
                        No Data...
                      </h1>
                    )}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="tabs-profileJustify"
                  role="tabpanel"
                  aria-labelledby="tabs-profile-tabJustify"
                >
                  <div className="items-start">
                    {requestHistory?.map((item, i) => (
                      <ListItem
                        key={item?.id}
                        name={item?.name}
                        from={item?.from}
                        to={item?.to}
                        distance={item?.distance}
                        item={item}
                        requiredDate={item?.reqDate}
                        mobile={item.requestedBy?.mobile}
                        requiredTime={item?.reqTime}
                        vehicleType={item?.vehicleType}
                        description={item?.description}
                        status={item?.status}
                        lastUpdated={null}
                        createdAt={item?.createdAt}
                        type={userLevel}
                        onViewDetails={(type) => {
                          setValues({
                            vehicleNo: item.providedVehicle?.vehicleNo,
                            driverName: item.providedVehicle?.driverName,
                            driverTele: item.providedVehicle?.driverPhone,
                            description: item?.providedVehicle?.description,
                            isDisabled: true,
                          });
                          setModalType(type);
                          setIsModal(true);
                        }}
                        onApprove={() => {
                          if (userLevel === USER_TYPES.ADMIN) {
                            setSelectedData({
                              id: item?._id,
                              type: REQUEST_STATUSES.PROVIDED,
                            });
                            setModalType(MODAL_TYPES.PROVIDE_VEHICLE);
                            setValues({isInformed:false})
                            setIsModal(true);
                          } else if (userLevel === USER_TYPES.DIRECTORS) {
                            setSelectedData({
                              id: item?._id,
                              type: REQUEST_STATUSES.APPROVED,
                            });
                            setAlert(true);
                          }
                        }}
                        onDiscard={() => {
                          setSelectedData({
                            id: item?._id,
                            type: REQUEST_STATUSES.DECLINE,
                          });
                          setAlert(true);
                        }}
                        onDecline={() => {
                          setSelectedData({
                            id: item?._id,
                            type: REQUEST_STATUSES.DECLINE,
                          });
                          setIsModal(true);
                          setModalType(MODAL_TYPES.DECLINE_REQUEST);
                        }}
                        onEdit={() => {
                          console.log(item);
                          setSelectedData({
                            id: item?._id,
                          });
                          setValues({
                            from: item.from,
                            to: item.to,
                            distance: item.distance,
                            time: item.reqTime,
                            date: item?.reqDate?.split("T")[0],
                            vehicleType: item.vehicleType,
                            description: item?.description,
                            isDisabled: false,
                          });
                          setModalType(MODAL_TYPES.EDIT_REQUEST);
                          setIsModal(true);
                        }}
                      />
                    ))}
                    {requestHistory?.length === 0 && (
                      <h1 className="text-center mt-28 text-lg font-bold w-5/5">
                        No Data...
                      </h1>
                    )}
                  </div>
                </div>

                <button
                  onClick={handleOpen}
                  title="New Request"
                  class="fixed z-90 bottom-16 right-16 bg-red-900 w-20 h-16 rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl hover:bg-red-800 hover:drop-shadow-2xl hover:animate-bounce duration-300"
                >
                  <img src={newFile} style={{ width: "24px" }} alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
