import React from "react";
import { Dialog } from "@headlessui/react";

export default function Modal({
  handleOk,
  handleCancel,
  open,
  title,
  children,
  buttons='',
  isButtonEnabled,
}) {
  return (
    <Dialog
      open={open}
      onClose={() => {}}
      className="fixed z-10 inset-0 overflow-y-auto"
    >
      <div className="flex items-center justify-center min-h-screen">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

        <div
          className={`relative bg-white rounded w-10/12 md:w-4/12  mx-auto p-10 pt-7`}
        >
          <Dialog.Title className="font-semibold text-center mb-5 text-xl ">
            {title}
          </Dialog.Title>
          <Dialog.Description>
            <div>{children}</div>
            <div>
              {buttons === "left" ? (
                <div className="flex justify-center mt-3 min-w-md">
                  <button
                    className="bg-green-300 m-3 px-6 py-2 w-1/2"
                    onClick={handleOk}
                  >
                    OK
                  </button>
                </div>
              ) : (
                <div className="flex justify-center mt-3 min-w-md">
                  <button
                    disabled={!isButtonEnabled}
                    className={`m-3 px-6 py-2 w-1/2 ${isButtonEnabled?'bg-green-300 text-black':'bg-gray-300 text-gray-500'}`}
                    onClick={handleOk}
                  >
                    OK
                  </button>
                  <button
                    className="bg-red-300 m-3  px-3 py-2 w-1/2"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              )}
            </div>
          </Dialog.Description>
        </div>
      </div>
    </Dialog>
  );
}
