import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import WithoutNav from "./components/Support/WithoutNav";
import Notification from './components/Notification';
import WithNav from "./components/Support/WithNav";
import Register from "./pages/Register";
import { RequireAuth } from "./components/RequiredAuth";
import Dashboard from "./pages/Dashboard";

function App() {
  return (
    <>
      <Notification />
      <Router>
        <Routes>
          <Route>{<Route path="/" element={<Home />} />}</Route>
          <Route element={<WithNav />}>
            <Route
              path="/Dashboard"
              element={
                <RequireAuth redirectTo="/">
                  <Dashboard />
                </RequireAuth>
              }
            />
          </Route>
          <Route element={<WithoutNav />}>
            <Route path="/login" element={<Login />} />
          </Route>
          <Route element={<WithNav />}>
            <Route path="/register" element={<Register />} />
          </Route>
          <Route element={<WithNav />}>
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
