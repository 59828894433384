import { ArrowCircleRightIcon } from "@heroicons/react/solid";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useState } from "react";
import LoginInput from "../components/LoginInput";
import LoginSelect from "../components/LoginSelect";
import Logo from "../assets/nbroLogo.jpeg";

function Register() {
  const navigate = useNavigate();

  const [customErrors, setCustomErrors] = useState({});

  return (
    <div class="flex justify-center ">
      <div class="flex justify-center flex-col lg:w-4/5 xl:max-w-screen-sm  ">
        <div className="mt-20 py-5 border-2">
          <div className="py-0  flex justify-center lg:justify-start lg:px-12">
            <div className="cursor-pointer flex items-center">
              <img
                className="h-20 w-20 object-cover mx-auto rounded-2xl shadow-lg"
                alt="nbro logo"
                src={Logo}
              ></img>
              <div className="text-2xl ml-5 text-red-900 tracking-wide font-semibold">
                <h2 className="text-5xl">NBRO</h2>
                <h2>Mobility Pal</h2>
              </div>
            </div>
          </div>
          <div class="mt-4 px-8 sm:px-24 md:px-48 lg:px-12 lg:mt-6 xl:px-12 xl:max-w-2xl">
            <h2
              className="text-center mb-6 text-3xl text-red-900 font-display font-semibold lg:text-left xl:text-3xl
              xl:text-bold"
            >
              Sign Up
            </h2>
            <Formik
              validateOnBlur={false}
              validateOnChange={false}
              initialValues={{ prefix: "Mr", password: "" }}
              validate={(values) => {
                const errors = {};
                let phoneNo =
                /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

                if (!values.name) {
                  errors.name = "Name is Required*";
                }

                if (!values.mobile) {
                  errors.mobile = "Mobile Number is Required*";
                } else if (!values.mobile.match(phoneNo)) {
                  errors.mobile = "Mobile Number format is incorrect";
                }
                //email
                if (!values.email) {
                  errors.email = "Email is Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }

                if (!values.empNumber) {
                  errors.empNumber = "Employee Number is Required*";
                }

                if (!values.division) {
                  errors.division = "Division is Required*";
                }

                if (!values.position) {
                  errors.position = "Designation is Required*";
                }

                if (!values.password) {
                  errors.password = "Password Is Required*";
                } else if (values.password.length < 6) {
                  errors.password = "Password Is Too Short*";
                }

                if (!values.passwordConfirm) {
                  errors.passwordConfirm = "Confirm Password Is Required*";
                } else if (values.password != values.passwordConfirm) {
                  errors.passwordConfirm = "Passwords Not Matching*";
                }
                setCustomErrors(errors);
                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                      name: values.name,
                      prefix: values.prefix,
                      division: values.division,
                      position: values.position,
                      empNumber: values.empNumber,
                      mobile: values.mobile,
                      password: values.password,
                    }),
                  };
                  const validateResults = await fetch(
                    `${process.env.REACT_APP_BACKEND_URL}/api/users/initial-validate`,
                    requestOptions
                  );
                  const response = await validateResults.json();
                  if ("success" in response) {
                    const requestPayload = {
                      method: "POST",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify({
                        name: values.name,
                        prefix: values.prefix,
                        division: values.division,
                        position: values.position,
                        empNumber: values.empNumber,
                        password: values.password,
                        mobile: values.mobile,
                        email: values.email,
                      }),
                    };
                    fetch(
                      `${process.env.REACT_APP_BACKEND_URL}/api/users/signup`,
                      requestPayload
                    )
                      .then(() => {
                        navigate("/login");
                      })
                      .catch(() => {
                        customErrors.passwordConfirm =
                          "Oops! Something went wrong please try again later";
                        setCustomErrors(customErrors);
                      });
                  } else {
                    customErrors.empNumber = "Entered Employee Number is taken";
                    setCustomErrors(customErrors);
                  }
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div class="grid grid-cols-2 gap-6">
                    <div class="relative z-0 mb-4 lg:mt-0 mt-2 w-full group">
                      <LoginSelect
                        type={"select"}
                        name={"division"}
                        onChange={handleChange}
                        value={values.division}
                        error={errors}
                        title={"Division"}
                      >
                        <option value={null}>Select</option>
                        <option>LRRMD</option>
                        <option>ESSD</option>
                        <option>BMRTD</option>
                        <option>HSPTD</option>
                        <option>GETD</option>
                        <option>PMD</option>
                        <option>AD</option>
                        <option>FD</option>
                        <option>PU</option>
                        <option>IAU</option>
                      </LoginSelect>
                    </div>
                    <div class="relative z-0 mb-4 w-full group lg:mt-0 mt-2">
                      <LoginSelect
                        type={"select"}
                        name={"position"}
                        onChange={handleChange}
                        value={values.position}
                        error={errors}
                        title={"Designation"}
                      >
                        <option value={null}>Select</option>
                        <option>Director</option>
                        <option>Senior Scientist</option>
                        <option>Scientist</option>
                        <option>Manager</option>
                        <option>TO</option>
                        <option>MA (Non tech)</option>
                        <option>MA (Tech)</option>
                        <option>Office Aid</option>
                        <option>Other</option>
                      </LoginSelect>
                    </div>
                  </div>
                  <div className="text-sm font-bold text-gray-700 tracking-wide">
                    {"Name"}
                  </div>
                  <div class="flex justify-between">
                    <div class="relative z-0 mb-6 w-2/12 group ">
                      <LoginSelect
                        px={"px-2 md:px-4"}
                        type={"select"}
                        name={"prefix"}
                        onChange={handleChange}
                        value={values.prefix}
                        error={null}
                        title={""}
                      >
                        {/* <option>Select</option> */}
                        <option>Mr</option>
                        <option>Ms</option>
                        <option>Dr</option>
                      </LoginSelect>
                    </div>
                    <div class="relative z-0 mb-6 mt-1 w-full group">
                      <LoginInput
                        pl={3}
                        pr={10}
                        type={"text"}
                        name={"name"}
                        onChange={handleChange}
                        value={values.name}
                        placeholder={"Eg - Sunimal Perera"}
                        error={errors}
                        title={""}
                      />
                    </div>
                  </div>
                  <div class="mb-6">
                    <LoginInput
                      pl={3}
                      pr={10}
                      type={"tel"}
                      name={"mobile"}
                      onChange={handleChange}
                      value={values.mobile}
                      placeholder={"Eg - 0714387452"}
                      error={errors}
                      title={"Mobile Number"}
                    />
                  </div>
                  <div class="mb-6">
                    <LoginInput
                      pl={3}
                      pr={10}
                      type={"text"}
                      name={"email"}
                      onChange={handleChange}
                      value={values.email}
                      placeholder={"Eg - amila@gmail.com"}
                      error={errors}
                      title={"Email"}
                    />
                  </div>
                  <div class="mb-6">
                    <LoginInput
                      type={"text"}
                      name={"empNumber"}
                      onChange={handleChange}
                      value={values.empNumber}
                      placeholder={"Eg - 105815"}
                      error={customErrors}
                      title={"Employee Number"}
                    />
                  </div>
                  <div class="mb-6">
                    <LoginInput
                      type={"password"}
                      name={"password"}
                      onChange={handleChange}
                      value={values.password}
                      placeholder={""}
                      error={errors}
                      title={"Password*"}
                    />
                  </div>
                  <div class="mb-6">
                    <LoginInput
                      type={"password"}
                      name={"passwordConfirm"}
                      onChange={handleChange}
                      value={values.passwordConfirm}
                      placeholder={""}
                      error={customErrors}
                      title={"Confirm Password*"}
                    />
                  </div>
                  <div className="mt-10 flex justify-center">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="text-red-900 border-2 border-red-900 py-3 px-7 rounded-2xl tracking-wide
                      font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-red-50
                      shadow-lg"
                    >
                      Sign Up
                    </button>
                  </div>
                </form>
              )}
            </Formik>
            <div className="mt-8 text-sm font-display font-semibold text-gray-700 text-center">
              Already have an account ?{" "}
              <a
                className="cursor-pointer text-yellow-600 hover:text-yellow-500"
                href="/login"
              >
                Login
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
