export const HARVEST_DATE_TYPES = {
    PENDING: 'pending',
    APPROVED: 'approved',
    DECLINE: 'decline',
  };

  export const REQUEST_STATUSES = {
    PENDING: 'pending',
    APPROVED: 'approved',
    PROVIDED: 'provided',
    DECLINE: 'decline',
  };

  export const USER_TYPES = {
    USER: 'USER',
    ADMIN: 'ADMIN',
    DIRECTORS: 'DIRECTORS',
  };

  export const MODAL_TYPES = {
    NEW_REQUEST: 'New Request',
    EDIT_REQUEST: 'Edit Request',
    PROVIDE_VEHICLE: 'Provide Vehicle',
    DECLINE_REQUEST: 'Decline Request',
  };