import React, { useEffect, useState } from "react";

const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      console.log("we are being triggered :D");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };
  return (
    <div
      class={`fixed bottom-24 w-full ${
        supportsPWA && isVisible ? "block" : "hidden"
      }`}
    >
      <div class="bg-slate-900 flex justify-center text-slate-100 mx-5 py-4 rounded-lg shadow hover:shadow-xl transform hover:-translate-y-[0.125rem] transition duration-100 ease-linear">
        <div class="w-full absolute">
          <button
            onClick={() => setIsVisible(false)}
            class="absolute float-right right-5 bg-slate-800 hover:bg-slate-700/70 text-slate-400 hover:text-slate-200 h-7 w-7 rounded-full flex justify-center items-center"
          >
            <svg class="h-4 w-4 fill-current items-center" viewBox="0 0 20 20">
              <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
            </svg>
          </button>
        </div>
        <button
          onClick={onClick}
          className=" text-gray-900 border-2  py-0 px-3 rounded-full
        font-semibold bg-white
        shadow-sm shadow-white"
        >
          Click Here To Install
        </button>
      </div>
    </div>
  );
};

export default InstallPWA;
