import React, { useEffect, useState } from "react";
import Logo from "../assets/nbroLogo.jpeg";

import { getUserName, isAuthenticated, logout } from "../Authentication/Auth";
import { useNavigate } from "react-router-dom";
import { getNotifications, submitClick } from "./action";

function Nav() {
  let navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (isAuthenticated()) {
      init();
    }
  }, []);

  const init = async () => {
    const response = await getNotifications();
    if (response?.length) {
      setNotifications(response);
    }
  };

  const handleClick = async (id) => {
    const response = await submitClick(id);
    if (response) {
      init();
    }
  };

  return (
    <nav
      className="
      fixed
    z-50
    w-full
    flex flex-wrap
    items-center
    justify-between
    py-2
    bg-gray-100
    text-gray-500
    hover:text-gray-700
    focus:text-gray-700
    shadow-lg
    navbar navbar-expand-lg navbar-light
    "
    >
      <div className="container-fluid w-full flex flex-wrap items-center justify-between px-6">
        <div className="items-center">
          <a
            className=" flex items-center text-gray-900 hover:text-gray-900 focus:text-gray-900 mt-2 lg:mt-0 mr-1"
            href="home"
          >
            <img src={Logo} style={{ height: 35 }} alt="" loading="lazy" />
          </a>
        </div>

        {isAuthenticated() ? (
          <div className="flex items-center relative">
            <div className="dropdown relative">
              <a
                className="dropdown-toggle flex items-center hidden-arrow mr-4 "
                href="#"
                id="dropdownMenuButton2"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src="https://png.pngtree.com/png-clipart/20210915/ourlarge/pngtree-avatar-placeholder-abstract-white-blue-green-png-image_3918476.jpg"
                  className="rounded-full"
                  style={{ height: 25, width: 25 }}
                  alt=""
                  loading="lazy"
                />
                <h1 className="ml-2">{getUserName()}</h1>
              </a>
              <ul
                className=" dropdown-menu min-w-max absolute bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 hidden m-0 bg-clip-padding border-none left-auto right-0"
                aria-labelledby="dropdownMenuButton2"
              >
                <li>
                  <a
                    className=" dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                    onClick={() =>
                      logout((res) => {
                        if (res) {
                          location.reload();
                          navigate("/");
                        }
                      })
                    }
                  >
                    Logout
                  </a>
                </li>
              </ul>
            </div>
            <div className="dropdown relative">
              <a
                className=" text-gray-500 hover:text-gray-700 focus:text-gray-700 dropdown-toggle hidden-arrow flex items-center"
                href="#"
                id="dropdownMenuButton1"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="bell"
                  className="w-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"
                  ></path>
                </svg>
                <span className="text-white bg-red-700 absolute rounded-full text-xs -mt-2.5 ml-2 py-0 px-1.5">
                  {notifications?.filter((n) => !n?.isClicked).length || 0}
                </span>
              </a>
              <ul
                className=" dropdown-menu min-w-max absolute hidden bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding border-none left-auto right-0"
                aria-labelledby="dropdownMenuButton1"
              >
                {notifications?.map((notification) => (
                  <li
                    className="flex flex-row hover:bg-gray-100 py-1"
                    key={notification?._id}
                    onClick={() =>
                      !notification?.isClicked && handleClick(notification?._id)
                    }
                  >
                    <div className="flex justify-center items-center py-0.5">
                      <div
                        className={` p-0.5 m-2 mx-2 h-full ${
                          !notification?.isClicked
                            ? "bg-red-800"
                            : "bg-gray-300 "
                        }`}
                      ></div>
                    </div>
                    <a
                      className={`dropdown-item text-sm py-1 pr-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 `}
                      href={notification?.link}
                    >
                      <div>
                        <h2>
                          <b>{notification?.title}</b>
                        </h2>
                        <p>{notification?.body}</p>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : (
          <a href="/login">
            <b>Login</b>
          </a>
        )}
      </div>
    </nav>
  );
}

export default Nav;
