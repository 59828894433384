import { getToken } from "../Authentication/Auth";

export const declineRequest = async (values, id) => {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/request/user-status`, {
    method: "POST",
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    }),
    body: JSON.stringify({
      id: id,
      reason: values.reason,
    }),
  })
    .then(response => response)
    .catch((error) => console.log(error));
};

export const editRequest = async (values, id) => {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/request`, {
    method: "PUT",
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    }),
    body: JSON.stringify({
      id: id,
      from: values.from,
      to: values.to,
      distance: values.distance,
      date: values.date,
      time: values.time,
      vehicleType: values.vehicleType,
      description: values.description,
    }),
  })
    .then((res) => res.json())
    .then((response) => response)
    .catch((error) => console.log(error));
};

