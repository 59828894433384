import React from "react";
import ReactStars from "react-rating-stars-component";
import { HARVEST_DATE_TYPES, MODAL_TYPES, REQUEST_STATUSES, USER_TYPES } from "../../config/constants/general";
import edit from "../../assets/edit.svg";
import car from "../../assets/car.png";
import van from "../../assets/van.png";
import cab from "../../assets/pickup.png";
import ActionButtons from "./ActionButtons";
import { getUserId } from "../../Authentication/Auth";
import { dateString } from "../../common/utils";

const images={
  car:car,
  van:van,
  cab:cab,
}

function ListItem ({
  name,
  type,
  item,
  state,
  from,
  to,
  distance,
  requiredDate,
  requiredTime,
  vehicleType,
  rating,
  description,
  status,
  mobile,
  onApprove,
  onDiscard,
  onDecline,
  onViewDetails,
  onEdit,
  lastUpdated,
  createdAt,
}) {
  return (
    <div className="mb-4 rounded-lg  shadow-lg bg-blue-100">
      <div class="flex flex-col p-4 pb-0 sm:p-0 sm:flex-row w-full  lg:h-auto ">
        <div className="flex justify-between md:w-1/5 ">
          <div className="w-1/2 sm:w-full pl-4">
            <img
              class="w-full md:h-auto object-cover rounded-t-lg md:rounded-lg"
              src={images[vehicleType?.toLowerCase()]}
              alt=""
            />
          </div>
          <div className="flex sm:hidden flex-col justify-start mt-2">
              <button
                disabled
                className={`${
                  status === REQUEST_STATUSES.PENDING
                    ? "bg-orange-500"
                    : status === REQUEST_STATUSES.APPROVED
                    ? "bg-yellow-500"
                    : status === REQUEST_STATUSES.PROVIDED
                    ? "bg-green-500"
                    : "bg-red-500"
                }  px-3 text-white rounded-xl`}
              >
                {status === REQUEST_STATUSES.PENDING
                  ? "Pending"
                  : status === REQUEST_STATUSES.APPROVED
                  ? "Approved"
                  : status === REQUEST_STATUSES.PROVIDED
                  ? "Provided"
                  : "Declined"}
              </button>
              {item?.requestedBy?._id === getUserId() &&
                status != REQUEST_STATUSES.PROVIDED && (
                  <div className="mx-auto mt-8 sm:my-0">
                    <div
                      onClick={onEdit}
                      className="bg-slate-100 px-4 py-3 rounded-xl shadow-sm mx-4 cursor-pointer hover:bg-zinc-50"
                    >
                      <img src={edit} style={{ width: "24px" }} alt="" />
                    </div>
                  </div>
                )}
            </div>
        </div>
        <div class="px-4 flex flex-col justify-start w-full md:w-3/5 md:mt-3">
          <h5 class="text-gray-900 text-lg font-semibold ">
            {`${item?.requestedBy?.prefix} ${item?.requestedBy?.name}`}{" "}
          </h5>
          {item?.approvedBy && (
            <h5 class="text-gray-900 text-base  font-medium flex ">
              <span className="font-semibold">Approved By : </span> &nbsp;{" "}
              {`${item?.approvedBy?.position} (${item?.approvedBy?.division})`}
            </h5>
          )}
          <h5 class="text-gray-900 text-base font-medium flex">
            <span className="font-semibold">Required date & time from:</span> &nbsp;
            <b>{`${item?.reqDateTimeFrom?.split("T")[0]} : ${dateString(item?.reqDateTimeFrom)}`}</b>
          </h5>
          <h5 class="text-gray-900 text-base font-medium flex">
            <span className="font-semibold">Required date & time to:</span> &nbsp;
            <b>{`${item?.reqDateTimeTo?.split("T")[0]} : ${dateString(item?.reqDateTimeTo)} `}</b>
          </h5>
          {/* <h5 class="text-gray-900 text-base font-medium flex">
            <span className="font-semibold">Created At:</span> &nbsp;
            {createdAt?.split("T")[0]}
          </h5> */}

          <h5 class="text-gray-900 text-base font-medium flex">
            <span className="font-semibold">Starting Location:</span> &nbsp;{from}
          </h5>
          <h5 class="text-gray-900 text-base  font-medium flex ">
            <span className="font-semibold">Destination : </span> &nbsp; {to}
          </h5>
          <h5 class="text-gray-900 text-base  font-medium flex ">
            <span className="font-semibold">Distance : </span> &nbsp; {distance}
          </h5>
          {mobile && (
            <h5 class="text-gray-900 text-base  font-medium flex ">
              <span className="font-semibold">Mobile No : </span> &nbsp;{" "}
              {mobile}
            </h5>
          )}
          {description && (
            <h5 class="text-gray-900 text-base  font-medium flex ">
              <span className="font-semibold">More info : </span> &nbsp;{" "}
              {description}
            </h5>
          )}

          {/* <h5 class="text-gray-900 text-base font-medium flex ">
            <span className="font-semibold">
              {lastUpdated &&
                `Updated at : 
            ${lastUpdated}`}
            </span>
          </h5> */}

          <div className="-mt-2">
            {/* <ReactStars
                count={5}
                // onChange={ratingChanged}
                value={rating}
                edit={false}
                size={24}
                activeColor="#ffd700"
              /> */}
          </div>
          {status === REQUEST_STATUSES.PROVIDED && (
            <div className="mt-4  mb-1">
              <button
                onClick={() => onViewDetails(MODAL_TYPES.PROVIDE_VEHICLE)}
                className="bg-blue-500 p-2 text-white rounded-xl "
              >
                View Details
              </button>
            </div>
          )}
          {!item?.userStatus?.isDecline && (
            <ActionButtons
              key={item?.id}
              status={status}
              type={type}
              cancelStatus={item.requestedBy._id === getUserId()}
              onApprove={onApprove}
              onDiscard={onDiscard}
              onDecline={onDecline}
            />
          )}
          {item?.editStatus?.isEdited && (
            <div className="mt-4 px-4 py-2 bg-white rounded-xl shadow-sm">
              <p className="text-black text-base font-bold">
                {`User has edited the request @ ${
                  item?.editStatus?.editAt?.split("T")[0]
                } ${dateString(item?.editStatus?.editAt)}`}
              </p>
            </div>
          )}
          {item?.userStatus?.isDecline && (
            <div className="mt-4 px-4 py-2 bg-red-100 rounded-xl shadow-sm">
              <p className="text-red-500 text-lg font-bold">
                User has cancelled the request!
              </p>
              <p>{item?.userStatus?.reason || ""}</p>
            </div>
          )}
        </div>

        <div className="justify-start items-end mt-5 hidden sm:flex flex-col md:w-1/5 px-4 py-1">
          <button
            disabled
            className={`${
              status === REQUEST_STATUSES.PENDING
                ? "bg-orange-500"
                : status === REQUEST_STATUSES.APPROVED
                ? "bg-yellow-500"
                : status === REQUEST_STATUSES.PROVIDED
                ? "bg-green-500"
                : "bg-red-500"
            }  px-3 text-white rounded-xl`}
          >
            {status === REQUEST_STATUSES.PENDING
              ? "Pending"
              : status === REQUEST_STATUSES.APPROVED
              ? "Approved"
              : status === REQUEST_STATUSES.PROVIDED
              ? "Provided"
              : "Declined"}
          </button>
          {item?.requestedBy?._id === getUserId() &&
            status != REQUEST_STATUSES.PROVIDED && (
              <div className="my-3 sm:my-7">
                <div
                  onClick={onEdit}
                  className="bg-slate-100 px-4 py-3 rounded-xl shadow-sm mx-2 cursor-pointer hover:bg-zinc-50"
                >
                  <img src={edit} style={{ width: "24px" }} alt="" />
                </div>
              </div>
            )}
        </div>
      </div>
      <p className="mb-4 text-right pb-2 pr-4">{createdAt?.split("T")[0]}</p>
    </div>
  );
}

export default ListItem;
